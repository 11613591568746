import React, { useState, useEffect } from 'react';
import './assets/app.css'
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';

function Calculator() {
  const [input, setInput] = useState('');
  const [history, setHistory] = useState([]);
  const [currentDateTime, setCurrentDateTime] = useState('');
  const [showScientificButtons, setShowScientificButtons] = useState(false);

  useEffect(() => {
    // Update the current date and time every second
    const intervalId = setInterval(() => {
      const now = new Date();
      setCurrentDateTime(now.toLocaleString());
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  const toggleScientificButtons = () => {
    setShowScientificButtons((prevShow) => !prevShow);
  };
  const handleTextareaChange = (event) => {
    // Update the history array when the textarea content changes
    const newHistory = event.target.value.split('\n');
    setHistory(newHistory);
  };

  const handleButtonClick = (value) => {
    setInput((prevInput) => prevInput + value);
  };

  const handleClear = () => {
    setInput('');
  };

  const handleBackspace = () => {
    setInput((prevInput) => prevInput.slice(0, -1));
  };

  const handleSquareRoot = () => {
    try {
      const sqrtResult = Math.sqrt(eval(input));
      setInput(sqrtResult.toString());
      setHistory([...history, `√(${input}) = ${sqrtResult.toString()}`]);
    } catch (error) {
      setInput('Error');
    }
  };

  const handleSquare = () => {
    try {
      const result = Math.pow(eval(input), 2);
      setInput(result.toString());
      setHistory([...history, `(${input})^2 = ${result.toString()}`]);
    } catch (error) {
      setInput('Error');
    }
  };

  const handleMultiplicativeInverse = () => {
    try {
      const num = parseFloat(eval(input));
      if (num !== 0) {
        const result = 1 / num;
        setInput(result.toString());
        setHistory([...history, `1/(${num}) = ${result.toString()}`]);
      } else {
        setInput('Error: Division by zero');
      }
    } catch (error) {
      setInput('Error');
    }
  };

  const handleFactorial = () => {
    try {
      const num = parseFloat(eval(input));
      if (num >= 0 && Number.isInteger(num)) {
        let result = 1;
        for (let i = 1; i <= num; i++) {
          result *= i;
        }
        setInput(result.toString());
        setHistory([...history, `${num}! = ${result.toString()}`]);
      } else {
        setInput('Error: Invalid input for factorial');
      }
    } catch (error) {
      setInput('Error');
    }
  };

  const handleLogarithm = () => {
    try {
      const num = parseFloat(eval(input));
      if (num > 0) {
        const result = Math.log10(num);
        setInput(result.toString());
        setHistory([...history, `log(${num}) = ${result.toString()}`]);
      } else {
        setInput('Error: Invalid input for logarithm');
      }
    } catch (error) {
      setInput('Error');
    }
  };
  const handleNaturalLog = () => {
    try {
      const result = Math.log(parseFloat(input));
      setInput(result.toString());
      setHistory([...history, `ln(${input}) = ${result.toString()}`]);
    } catch (error) {
      setInput('Error: Invalid input for natural logarithm');
    }
  };

  const handleTrigonometric = (operation) => {
    try {
      const result = Math[operation](eval(input));
      setInput(result.toString());
      setHistory([...history, `${operation}(${input}) = ${result.toString()}`]);
    } catch (error) {
      setInput('Error');
    }
  };
  const handlePowerOfTen = () => {
    try {
      const result = Math.pow(10, eval(input));
      setInput(result.toString());
      setHistory([...history, `10^(${input}) = ${result.toString()}`]);
    } catch (error) {
      setInput('Error');
    }
  };
  const pivalue = () => {
    setInput('3.142');
  };

  const handleEqual = () => {
    try {
      const numArray = input.split('^');

      // Check if numArray is an array
      if (Array.isArray(numArray) && numArray.length === 2) {
        const base = parseFloat(numArray[0]);
        const exponent = parseFloat(numArray[1]);

        if (!isNaN(base) && !isNaN(exponent)) {
          setInput(Math.pow(base, exponent));
          setHistory([...history, `${input.toString()} = ${Math.pow(base, exponent).toString()}`]);
        } else {
          setInput('Error: Invalid input for exponentiation');
        }
      } else {
        // If not an array, evaluate the expression
        setInput(eval(input).toString());
        setHistory([...history, `${input.toString()} = ${eval(input).toString()}`]);
      }

    } catch (error) {
      setInput('Error');
    }
  };

  const handleResetHistory = () => {
    setHistory([]);
  };

  const downloadHistory = () => {
    const formattedHistory = history.join('\n');
    const blob = new Blob([formattedHistory], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'calculation_history.txt';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const scienceButtonStyle = { 
    background: 'linear-gradient(to bottom, #ffe08b, #ffc437)', 
    color: "black", 
    fontSize: '12px' 
  };
  const numButtonStyle = { 
    background: 'linear-gradient(to bottom, #eaeaea, #afafaf)',
     color: "black" 
    };
  const basicOperationButtonStyle = { 
    background: 'linear-gradient(to bottom, #bccff7, #7ea8fd)',
     color: "black" 
    };

  return (
    <div className="container mt-5">
      <Container>
        <Row className='d-flex justify-content-center align-items-center'>
          <Col md={6} lg={5} xl={4} xxl={4}>
            <Card>
              <Card.Body style={{ background: '#eaeaea', }}>
                <b>Calculator </b>
                <Row className="border m-1 p-1" style={{ background: '#c0c0c0' }}>
                  <Row className="container w-80% d-flex justify-content-center align-items-center m-1" >
                    <Form>
                      <Form.Control type="text" value={input} placeholder="" readOnly style={{ height: '70px', fontSize: '30px', background: '#b4a77a', textAlign: "right", border: "2px solid black" }} />
                    </Form>
                  </Row>
                </Row>
                  <label className="switch">
                    <input type="checkbox" checked={showScientificButtons} onChange={toggleScientificButtons} />
                    <span className="slider round info" style={{fontSize: "12px", marginLeft: "5px" }}>scientific</span>
                  </label>
                  {showScientificButtons && (
                    <>
                  
                <Row className="d-flex justify-content-center align-items-center mt-2">
                  <button
                    onClick={handleLogarithm}
                    className="col-2 btn btn-secondary m-1" style={scienceButtonStyle}
                  >
                    log
                  </button>
                  <button
                    onClick={() => handleTrigonometric('acos')}
                    className="col-2 btn btn-secondary m-1" style={scienceButtonStyle}
                  >acos
                  </button>
                  <button
                    onClick={() => handleTrigonometric('asin')}
                    className="col-2 btn btn-secondary m-1" style={scienceButtonStyle}
                  >asin
                  </button>
                  <button
                    onClick={() => handleTrigonometric('atan')}
                    className="col-2 btn btn-secondary m-1" style={scienceButtonStyle}
                  >atan
                  </button>
                </Row>
                <Row className="d-flex justify-content-center align-items-center mt-2">
                  <button
                    onClick={handlePowerOfTen}
                    className="col-2 btn btn-secondary m-1" style={scienceButtonStyle}
                  >
                    10<sup>x</sup>
                  </button>
                  <button
                    onClick={() => handleTrigonometric('cos')}
                    className="col-2 btn btn-secondary m-1" style={scienceButtonStyle}
                  >cos
                  </button>
                  <button
                    onClick={() => handleTrigonometric('sin')}
                    className="col-2 btn btn-secondary m-1" style={scienceButtonStyle}
                  >sin
                  </button>
                  <button
                    onClick={() => handleTrigonometric('tan')}
                    className="col-2 btn btn-secondary m-1" style={scienceButtonStyle}
                  >tan
                  </button>
                </Row>
                <Row className="d-flex justify-content-center align-items-center mt-2">
                  <button
                    key='%'
                    onClick={() => handleButtonClick("%".toString())}
                    className="col-2 btn btn-secondary m-1" style={scienceButtonStyle}
                  >
                    %
                  </button>
                  <button
                    onClick={pivalue}
                    className="col-2 btn btn-secondary m-1" style={scienceButtonStyle}
                  >pii
                  </button>
                  <button
                    key='^'
                    onClick={() => handleButtonClick("^".toString())}
                    className="col-2 btn btn-secondary m-1" style={scienceButtonStyle}
                  >x<sup>y</sup>
                  </button>
                  <button
                    onClick={handleNaturalLog}
                    className="col-2 btn btn-secondary m-1" style={scienceButtonStyle}
                  >ln
                  </button>
                </Row>
                <Row className="d-flex justify-content-center align-items-center mt-2">
                  <button
                    onClick={handleSquareRoot}
                    className="col-2 btn btn-secondary m-1" style={scienceButtonStyle}
                  >
                    √
                  </button>
                  <button
                    onClick={handleSquare}
                    className="col-2 btn btn-secondary m-1" style={scienceButtonStyle}
                  >x<sup>2</sup>
                  </button>
                  <button
                    onClick={handleMultiplicativeInverse}
                    className="col-2 btn btn-secondary m-1" style={scienceButtonStyle}
                  >1\x
                  </button>
                  <button
                    onClick={handleFactorial}
                    className="col-2 btn btn-secondary m-1" style={scienceButtonStyle}
                  >x!
                  </button>
                </Row>
                </>
                )}
                <Row className="d-flex justify-content-center align-items-center mt-2">
                  <button
                    key='('
                    onClick={() => handleButtonClick('('.toString())}
                    className="col-2 btn btn-secondary m-1" style={scienceButtonStyle}
                  >
                    (
                  </button>
                  <button
                    key=')'
                    onClick={() => handleButtonClick(')'.toString())}
                    className="col-2 btn btn-secondary m-1" style={scienceButtonStyle}
                  >
                    )
                  </button>
                  <button
                    onClick={handleClear}
                    className="col-2 btn btn-danger m-1"
                  >C
                  </button>

                  <button
                    onClick={handleBackspace}
                    className="col-2 btn btn-warning m-1"
                  >
                    ⌫
                  </button>
                </Row>
                <Row className="d-flex justify-content-center align-items-center" >
                    <button
                      key='7'
                      onClick={() => handleButtonClick('7'.toString())}
                      className="col-2 btn btn-secondary m-1" style={numButtonStyle}
                    >
                      7
                    </button>
                    <button
                      key='8'
                      onClick={() => handleButtonClick('8'.toString())}
                      className="col-2 btn btn-secondary m-1" style={numButtonStyle}
                    >
                      8
                    </button>
                    <button
                      key='9'
                      onClick={() => handleButtonClick('9'.toString())}
                      className="col-2 btn btn-secondary m-1" style={numButtonStyle}
                    >
                      9
                    </button>
                    <button
                      key='/'
                      onClick={() => handleButtonClick('/'.toString())}
                      className="col-2 btn btn-secondary m-1" style={basicOperationButtonStyle}
                    >
                      /
                    </button>
                </Row>
                <Row className="d-flex justify-content-center align-items-center">
                <button
                      key='4'
                      onClick={() => handleButtonClick('4'.toString())}
                      className="col-2 btn btn-secondary m-1" style={numButtonStyle}
                    >
                      4
                    </button>
                <button
                      key='5'
                      onClick={() => handleButtonClick('5'.toString())}
                      className="col-2 btn btn-secondary m-1" style={numButtonStyle}
                    >
                      5
                    </button>
                <button
                      key='6'
                      onClick={() => handleButtonClick('6'.toString())}
                      className="col-2 btn btn-secondary m-1" style={numButtonStyle}
                    >
                      6
                    </button>
                <button
                      key='*'
                      onClick={() => handleButtonClick('*'.toString())}
                      className="col-2 btn btn-secondary m-1" style={basicOperationButtonStyle}
                    >
                      *
                    </button>
                </Row>
                <Row className="d-flex justify-content-center align-items-center">
                   <button
                   key='1'
                   onClick={() => handleButtonClick('1'.toString())}
                   className="col-2 btn btn-secondary m-1" style={numButtonStyle}
                 >
                   1
                 </button>
                   <button
                   key='2'
                   onClick={() => handleButtonClick('2'.toString())}
                   className="col-2 btn btn-secondary m-1" style={numButtonStyle}
                 >
                   2
                 </button>
                   <button
                   key='3'
                   onClick={() => handleButtonClick('3'.toString())}
                   className="col-2 btn btn-secondary m-1" style={numButtonStyle}
                 >
                   3
                 </button>
                   <button
                   key='-'
                   onClick={() => handleButtonClick('-'.toString())}
                   className="col-2 btn btn-secondary m-1" style={basicOperationButtonStyle}
                 >
                   -
                 </button>
                </Row>
                <Row className="d-flex justify-content-center align-items-center">
                    <button
                    key='0'
                    onClick={() => handleButtonClick('0'.toString())}
                    className="col-2 btn btn-secondary m-1" style={numButtonStyle}
                  >
                    0
                  </button>
                    <button
                    key='.'
                    onClick={() => handleButtonClick('.'.toString())}
                    className="col-2 btn btn-secondary m-1" style={numButtonStyle}
                  >
                    .
                  </button>
                    <button
                    key='='
                    onClick={handleEqual}
                    className="col-2 btn btn-secondary m-1" style={basicOperationButtonStyle}
                  >
                    =
                  </button>
                    <button
                    key='+'
                    onClick={() => handleButtonClick('+'.toString())}
                    className="col-2 btn btn-secondary m-1" style={basicOperationButtonStyle}
                  >
                    +
                  </button>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col className='mt-2' md={6} lg={5} xl={4} xxl={4}>
            <Card>
              <Card.Body style={{ background: "#fffce3", height: "500px", fontSize: "12px" }}><b style={{ color: '#91c8ff' }}>{currentDateTime}</b>
                <Form.Control
                  as="textarea"
                  value={history.join('\n')}
                  onChange={handleTextareaChange}
                  style={{ width: "100%", height: "90%", background: "none", border: "none", resize: "none", fontSize: "inherit" }}
                />
              </Card.Body>
            </Card>
            <Row className='container m-1'>
              <Col>
            <button
              onClick={downloadHistory}
              className="btn btn-success"
              style={{ width: '100%', fontSize: "12px" }}
            >
              Download History
            </button>
              </Col>
              <Col>
            <button
              onClick={handleResetHistory}
              className="btn btn-danger"
              style={{ width: '100%', fontSize: "12px" }}
            >
              Reset History
            </button>
              </Col>
            </Row>

          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Calculator;
